import { fr, nlBE } from "date-fns/locale";

export const languageData = [
  { code: "nl-BE", locale: nlBE, tideId: 1 },
  { code: "fr-BE", locale: fr, tideId: 2 },
];

export const languages = languageData.map((x) => x.code);
export const defaultLanguage = "nl-BE";

export function getLocale(code: string) {
  return languageData.find((l) => l.code === code)?.locale || nlBE;
}

export function getTideLanguageId(code: string) {
  return languageData.find((l) => l.code === code)?.tideId || 1;
}
